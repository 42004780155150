<script>
import Layout from '../../layouts/draws';
import { api } from '@/state/services';

export default {
  locales: {
    pt: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      influencer: {
        loading: true,
        errored: false,

        slug: 'rodrigoishiy',

        id: null,
        status: null,
        name: null,
        description: null,
        instagram: null,
        facebook: null,
        youtube: null,
        site: null,
        avatar: null,
        cover: null,
      },
      draws: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      }
    };
  },
  methods: {
    getInfluencer() {
      api
        .get('influencers/' + this.influencer.slug)
        .then((response) => {
          if (response.data.status === 'success') {
            this.influencer.id          = response.data.influencer.id
            this.influencer.status      = response.data.influencer.status
            this.influencer.name        = response.data.influencer.name
            this.influencer.slug        = response.data.influencer.slug
            this.influencer.description = response.data.influencer.description
            this.influencer.instagram   = response.data.influencer.instagram
            this.influencer.facebook    = response.data.influencer.facebook
            this.influencer.youtube     = response.data.influencer.youtube
            this.influencer.site        = response.data.influencer.site
            this.influencer.avatar      = response.data.influencer.avatar
            this.influencer.cover       = response.data.influencer.cover

            this.influencer.loading = false
          }
        })
        .catch(error => {
          this.influencer.errored = error
          this.influencer.loading = false
        })
        .finally(() => {
          this.influencer.loading = false
        })
    },
    getDraws() {
      api
        .get('influencers/' + this.influencer.slug + '/draws')
        .then((response) => {
          if (response.data.status === 'success') {
            this.draws.list = response.data.list
            this.draws.loading = false
          }
        })
        .catch(error => {
          this.draws.errored = error
          this.draws.loading = false
        })
        .finally(() => {
          this.draws.loading = false
        })
    }
  },
  mounted() {
    this.getInfluencer()
    this.getDraws()
  }
};
</script>

<template>
  <Layout>
    <div id="draws">
      <div v-if="influencer.cover" class="draw-cover" :style="'background-image: url(' + influencer.cover + ');'"></div>
      <div class="container">
        <div class="draws-header d-flex mb-5 px-3">
          <div class="influencer-avatar">
            <img :src="influencer.avatar">
          </div>
          <div class="influencer-details flex-fill align-self-center ml-3">
            <h4 class="m-0">{{ influencer.name }}</h4>
            <div class="influencer-social">
              <a v-if="influencer.instagram" class="mr-1" target="_blank" :href="influencer.instagram"><i class="bx bxl-instagram font-size-22 align-middle"></i></a>
              <a v-if="influencer.facebook" class="m-1" target="_blank" :href="influencer.facebook"><i class="bx bxl-facebook-square font-size-22 align-middle"></i></a>
              <a v-if="influencer.youtube" class="ml-1" target="_blank" :href="influencer.youtube"><i class="bx bxl-youtube font-size-22 align-middle"></i></a>
            </div>
          </div>
          <div class="influencer-security align-self-center d-none d-sm-block">
            <div class="d-flex">
              <i class="bx bxs-lock-alt font-size-18 mr-1"></i>
              <div>Site 100% seguro e criptografado</div>
            </div>
          </div>
        </div>
        <div class="draws-list">
          <h3 class="text-center">Sorteios em <strong class="text-danger">Andamento</strong></h3>
          <div class="row justify-content-center mt-5">
            <div class="col-md-6 col-lg-4" v-for="(draw, id) in draws.list " :key="id">
              <router-link tag="a" :to="'/draws/' + draw.slug">
                <div class="mb-4 shadow-lg">
                  <img class="img-fluid mb-3" :src="draw.image">
                  <div class="px-3 pb-3 text-center">
                    <h5><strong>{{ draw.name }}</strong></h5>
                    <button class="btn btn-danger btn-block text-uppercase">Participe</button>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="influencer-history mt-4 pt-4">
          <h3 class="text-center px-4">Conheça o <strong class="text-danger">{{ influencer.name }}</strong></h3>
          <div class="d-md-flex">
            <div class="influencer-avatar align-self-center text-center">
              <img :src="influencer.avatar">
            </div>
            <div v-html="influencer.description" class="p-4"></div>
          </div>
          <div class="text-center">
            <a v-if="influencer.instagram" class="mr-1" target="_blank" :href="influencer.instagram"><i class="bx bxl-instagram h1 align-middle"></i></a>
            <a v-if="influencer.facebook" class="m-1" target="_blank" :href="influencer.facebook"><i class="bx bxl-facebook-square h1 align-middle"></i></a>
            <a v-if="influencer.youtube" class="ml-1" target="_blank" :href="influencer.youtube"><i class="bx bxl-youtube h1 align-middle"></i></a>
          </div>
        </div>
        <div class="influencer-company mt-4 pt-4">
          <h3 class="text-center px-4">Conheça o <strong class="text-danger">Influenciadores Pelo Bem</strong></h3>
          <div class="p-4">
            <p>Somos a primeira plataforma do Brasil de ECONOMIA SOLIDÁRIA!</p>
            <p>Aqui ações se transformam em SOLIDARIEDADE.</p>
            <p>Juntos construímos pontes para um futuro com mais equidade.</p>
            <p>Conectamos pessoas, comércios, empresas, instituições e projetos sociais com o objetivo de criar SUSTENTABILIDADE permanente, beneficiando e gerando “RENDA EXTRA” e “BÔNUS” a TODOS.</p>
            <p>Transformamos solidariedade em ações, e possibilitamos aos nossos EMBAIXADORES, INSTITUIÇÕES, COMÉRCIOS, EMPRESAS E SERVIÇOS parceiros obtenham retorno financeiro por sua participação e empenho.</p>
            <p>Além de concorrer a diversos prêmios em nossa área de entretenimento premiado, acessar cursos de formação profissional e de capacitações e participar da Maior plataforma de economia solidaria do Brasil.</p>
            <p>Participando da plataforma em qualquer das ações de DOAÇÃO, você ganha CASH BACK e BÔNUS como incentivo. (vide regulamento de cada ação).</p>
          </div>
        </div>
        <div class="draws-footer mt-5 py-5 text-center font-size-12">
          Influenciadores Pelo Bem ®
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.draw-cover {
  background-position: center;
  background-size: cover;
  margin-bottom: 30px;
  height: 250px;
}

.influencer-avatar img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100px;
}

.influencer-history .influencer-avatar img {
  width: 200px;
  height: 200px;
  border-radius: 200px;
}

.shadow-lg {
  box-shadow: 0 0 3rem rgb(0 0 0 / 10%) !important;
}
</style>